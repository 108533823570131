<script lang="ts" setup>
import type { WorkflowItemFragment } from '@/generated/sdk'
import { links } from '@/router'
import { LargeModalLayout } from '@/ui/components'
import { useUserOrganization } from '@/user/composables'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import RunWorkflowForm from './RunWorkflowForm.vue'

const router = useRouter()
const props = defineProps<{
  workflow: WorkflowItemFragment
  open: boolean
}>()

const { fetch: fetchOrganization, insufficientFunds } = useUserOrganization()
const { workflow: editorWorkflow, refreshStatus } = useWorkflowDetails()

const emit = defineEmits<{
  close: []
}>()

onMounted(fetchOrganization)

async function handleRunWorkflow() {
  emit('close') // close the run modal

  const runningEditorWorkflow = editorWorkflow.value?.id === props.workflow.id
  if (runningEditorWorkflow) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    await refreshStatus()
  } else {
    await goToOutput()
  }
}

async function goToOutput() {
  await router.push(links.workflowData(props.workflow.id))
}
</script>

<template>
  <LargeModalLayout :open="open" title="Run workflow" @close="$emit('close')">
    <template #content>
      <template v-if="insufficientFunds">
        <h3>Insufficient funds</h3>
        <p>You don't have enough credits to run this workflow. Please top up your account to continue.</p>
      </template>

      <RunWorkflowForm
        v-else
        :workflow-id="workflow.id"
        :is-public-workflow="false"
        :show-header="false"
        @running="handleRunWorkflow"
      />
    </template>
  </LargeModalLayout>
</template>
