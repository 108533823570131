<script setup lang="ts">
import { links } from '@/router'
import { TwinIcon } from '@/ui/components'
import CheckMark from '@/ui/components/CheckMark.vue'
import { useSimpleMessage } from '@/ui/composables'
import { useWorkflowDetails } from '@/workflow-edit'
import { Button, Column, FormItem, Row } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import ExposeWorkflowModal from './ExposeWorkflowModal.vue'

const router = useRouter()
const { showMessage } = useSimpleMessage()
const { workflow, updateWorkflow } = useWorkflowDetails()
const publicLink = computed(() => {
  const route = router.resolve(links.publicRunWorkflow(workflow.value!.id))
  return `${window.location.origin}${route.href}`
})

const openExposeWorkflowModal = ref(false)

async function handleClickExposeWorkflow() {
  if (workflow.value?.isPublic) {
    await updateWorkflow({ isPublic: false })
  } else {
    openExposeWorkflowModal.value = true
  }
}

async function handleCopyLink() {
  if (!publicLink.value) return
  await navigator.clipboard.writeText(publicLink.value)
  showMessage('Link copied to clipboard')
}
</script>
<template>
  <Column gap="s">
    <FormItem label="Public access">
      <CheckMark
        :disabled="!workflow?.draft"
        :model-value="!!workflow?.isPublic"
        @update:model-value="handleClickExposeWorkflow"
      >
        Publicly accessible
      </CheckMark>

      <template v-if="workflow?.isPublic">
        <Row>
          <Button size="s" @click="handleCopyLink">
            <TwinIcon icon="Copy" />
            Copy public URL
          </Button>
        </Row>
      </template>
    </FormItem>
  </Column>

  <ExposeWorkflowModal v-model="openExposeWorkflowModal" />
</template>
