<script setup lang="ts">
import { links } from '@/router'
import { TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, Column, Dropdown, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { RouterLink } from 'vue-router'
import { useWorkflowApiCall } from './composables/useWorkflowApiCall'

const { showMessage } = useSimpleMessage()
const { curlCommand, apiKey } = useWorkflowApiCall()

async function handleCopyApiCall() {
  if (!curlCommand.value) return
  await navigator.clipboard.writeText(curlCommand.value)
  showMessage('API call copied to clipboard')
}
</script>
<template>
  <FormItem label="Integrate with API">
    <Dropdown>
      <template #trigger="{ toggle }">
        <Row>
          <Button size="s" @click="toggle">
            <TwinIcon icon="Eye" />
            Show cURL command
          </Button>
        </Row>
      </template>
      <template #content="{ close }">
        <Column>
          <Row padding="0" justify="between">
            <Row grow>
              <h3>Integrate with API</h3>
            </Row>
            <Button variant="clear" square @click="close">
              <TwinIcon icon="Cross" />
            </Button>
          </Row>

          <p class="form-description">
            To integrate with the API, you need to provide an API key. You can manage your API keys in the
            <RouterLink :to="links.organization()">organization settings</RouterLink>. More documentation about
            integrating with the API can be found there as well.
          </p>

          <FormItem label="API key">
            <TextInput v-model="apiKey" placeholder="Enter your API key" />
          </FormItem>

          <div :class="$style.code">
            <pre><code>{{ curlCommand }}</code></pre>
          </div>

          <Row justify="center">
            <Button color="primary" title="Copy public URL" @click="handleCopyApiCall">
              <TwinIcon icon="Copy" size="l" /> Copy
            </Button>
          </Row>
        </Column>
      </template>
    </Dropdown>
  </FormItem>
</template>

<style module>
.code {
  background: var(--grey-1-altbg);
  border: 1px solid var(--grey-2-lines);
  border-radius: 8px;
}

.code pre {
  width: 568px;
  margin: 0;
  padding: 8px;
  font-size: 14px;
}
</style>
