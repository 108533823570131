<script lang="ts" setup>
import { links } from '@/router'
import { CheckMark, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, Column, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import ChooseInputData from './ChooseInputData.vue'
import RunWorkflowSettings from './RunWorkflowSettings.vue'
import { useRunWorkflowForm } from './useRunWorkflowForm'

const { showMessage } = useSimpleMessage()

const runForm = useRunWorkflowForm()
const { data, shownArgNames, availableInputDatas, errors, runWorkflowDetails, initForm, submitRun, loadInputData } =
  runForm
const router = useRouter()

const props = defineProps<{
  workflowId: string
  isPublicWorkflow: boolean
  showHeader: boolean
}>()

const emit = defineEmits<{
  running: []
}>()

const canSaveInputData = computed(() => {
  // For now public workflows do not allow reusing input data
  return !props.isPublicWorkflow
})

onMounted(init)

async function init() {
  if (!props.workflowId) return
  await initForm({
    workflowId: props.workflowId,
    useInputData: canSaveInputData.value,
    isPublicWorkflow: props.isPublicWorkflow,
  })

  if (runWorkflowDetails.value?.draft) {
    return showMessage('This workflow is currently in draft mode. Please publish it before running it.', {
      type: 'danger',
      duration: 10000,
    })
  }
}

function selectInputData(id: string) {
  const input = availableInputDatas.value?.find((input) => input.id === id)
  if (!input) return
  data.selectedInputDataId = id
  loadInputData(input)
}

async function handleRunWorkflow() {
  const response = await submitRun()
  if (!response) return // Validation failed
  showMessage('Running workflow')
  emit('running')
}

async function clickEditWorkflow() {
  await router.push(links.workflowEdit(props.workflowId))
}
</script>

<template>
  <Column>
    <template v-if="showHeader">
      <h1>{{ runWorkflowDetails?.name || 'Untitled workflow' }}</h1>
      <p v-if="runWorkflowDetails?.description">{{ runWorkflowDetails.description }}</p>
      <br />
    </template>

    <template v-if="shownArgNames.length > 0">
      <template v-if="canSaveInputData && availableInputDatas && availableInputDatas.length > 0">
        <FormItem label="Previous input data" optional>
          <ChooseInputData :value="data.selectedInputDataId" :options="availableInputDatas" @select="selectInputData" />
        </FormItem>
        <hr />
      </template>

      <RunWorkflowSettings :run-form="runForm" />

      <template v-if="canSaveInputData">
        <hr />
        <FormItem>
          <Row>
            <CheckMark v-model="data.createNewInputData">Store this data for reuse in the future</CheckMark>
          </Row>
        </FormItem>
        <FormItem v-show="data.createNewInputData" label="Input data name" :error="errors.name">
          <TextInput v-model="data.name" placeholder="Choose a name for this input data" />
        </FormItem>
      </template>
    </template>

    <template v-else>
      <p>This workflow has no input arguments.</p>
    </template>

    <p v-if="runWorkflowDetails?.draft" class="dodo-color-danger">
      <template v-if="isPublicWorkflow">This workflow is unavailable while it is being edited.</template>
      <template v-else>This workflow can't be run while it is in draft mode.</template>
    </p>

    <Row justify="end">
      <Button v-if="showHeader && !isPublicWorkflow" @click="clickEditWorkflow"> Edit workflow </Button>
      <Button color="primary" variant="solid" :disabled="runWorkflowDetails?.draft" @click="handleRunWorkflow">
        <TwinIcon icon="Play" />
        Run now
      </Button>
    </Row>
  </Column>
</template>
