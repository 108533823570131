<script setup lang="ts">
import { useApiClient } from '@/api'
import type { NavEntry } from '@/navigation/components'
import { Navbar } from '@/navigation/components'
import { links } from '@/router'
import { TwinIcon } from '@/ui/components'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import RunWorkflowModal from '@/workflows/runworkflow/RunWorkflowModal.vue'
import { Button, Column, Dropdown, Icon, Spinner, Tooltip } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useEditorState, useEditorView, useWorkflowAutoLayout, useWorkflowDetails } from './composables'
import Editor from './Editor.vue'
import EditorBreadcrumbs from './EditorBreadcrumbs.vue'

defineProps<{
  workflowId: string
}>()

const { workflow, deleteWorkflow, cloneWorkflow, isRunning } = useWorkflowDetails()
const router = useRouter()
const { showMessage } = useSimpleMessage()
const { centerView } = useEditorView()
const { recalculateLayout } = useWorkflowAutoLayout()
const { getState } = useEditorState()
const { confirmDelete } = useConfirmDelete()

const runWorkflowModalOpen = ref(false)

const navEntries: NavEntry[] = [
  { position: 'start', isSeparator: true },
  { position: 'start', slot: 'breadcrumbs' },
  { position: 'end', slot: 'actions' },
]
watch(() => workflow.value?.id, setLayoutIfNeeded, { immediate: true })

async function setLayoutIfNeeded() {
  // Check if the workflow needs a layout update
  if (workflow.value && !getState('blockPositions')) {
    await recalculateLayout()
  }
}

const { client } = useApiClient()

async function setToDraft() {
  await client.setToDraft({ workflowId: workflow.value!.id })
  workflow.value!.draft = true
  showMessage('Workflow set to draft')
}

async function setToPublished() {
  await client.setToPublished({ workflowId: workflow.value!.id })
  workflow.value!.draft = false
  showMessage('Workflow set to published')
}

async function clickAutolayout() {
  await recalculateLayout()
}

function clickCenterView() {
  centerView()
}

function onMouseWheel(e: WheelEvent) {
  const isPinchZoom = e.ctrlKey && !Number.isInteger(e.deltaY)
  if (isPinchZoom) {
    e.preventDefault()
  }
}

async function clickDelete() {
  if (!workflow.value) return
  if (await confirmDelete({ name: workflow.value.name, type: 'workflow', superSecure: true })) {
    await deleteWorkflow()
    showMessage('Workflow deleted')
    await router.push(links.workflows())
  }
}

async function clickCloneWorkflow() {
  if (!workflow.value) return
  const newWorkflow = await cloneWorkflow()
  showMessage('Workflow duplicated')
  await router.push(links.workflowEdit(newWorkflow.id))
}
</script>

<template>
  <div :class="$style.EditorPage" @wheel="onMouseWheel">
    <Navbar :nav-entries="navEntries">
      <template #breadcrumbs>
        <EditorBreadcrumbs />
      </template>

      <template #actions>
        <Button v-if="!workflow?.draft" @click="setToDraft"> <TwinIcon icon="Edit" /> Edit </Button>
        <Button v-if="workflow?.draft" variant="solid" color="primary" @click="setToPublished">
          <Icon name="Publish" /> Publish
        </Button>

        <Tooltip v-if="workflow?.draft" text="Workflow is not published yet">
          <Button variant="solid" disabled> <TwinIcon icon="Play" /> Test </Button>
        </Tooltip>
        <Button v-else variant="solid" :disabled="isRunning" color="primary" @click="runWorkflowModalOpen = true">
          <TwinIcon icon="Play" /> Test
        </Button>

        <Button @click="router.push(links.workflowData(workflowId))"> <TwinIcon icon="NPS" />Results </Button>

        <Dropdown v-if="workflow" class="navbar-dropdown">
          <template #trigger="{ toggle }">
            <Button square title="More options" @click="toggle">
              <TwinIcon icon="More" />
            </Button>
          </template>
          <template #content>
            <Button variant="clear" :class="$style.dropdownButton" @click="router.push(links.runWorkflow(workflowId))">
              <TwinIcon icon="Play" />
              Run workflow
            </Button>
            <Button
              variant="clear"
              :disabled="!workflow?.draft"
              :class="$style.dropdownButton"
              @click="clickAutolayout"
            >
              <Icon name="Schema" /> Autolayout
            </Button>
            <Button variant="clear" :class="$style.dropdownButton" @click="clickCenterView()">
              <Icon name="Recenter" /> Center view
            </Button>
            <Button variant="clear" :class="$style.dropdownButton" @click="clickCloneWorkflow()">
              <TwinIcon icon="Copy" /> Duplicate workflow
            </Button>
            <Button
              variant="clear"
              :class="$style.dropdownButton"
              :disabled="!workflow.draft"
              color="danger"
              @click="clickDelete"
            >
              <TwinIcon icon="Delete" /> Delete workflow
            </Button>
          </template>
        </Dropdown>
      </template>
    </Navbar>

    <Editor v-if="workflow" />
    <Column v-else align="center" justify="center" style="justify-content: center; flex: 1">
      <Spinner large />
    </Column>

    <RunWorkflowModal
      v-if="workflow"
      :open="runWorkflowModalOpen"
      :workflow="workflow"
      @close="runWorkflowModalOpen = false"
    />
  </div>
</template>

<style module>
.EditorPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  touch-action: none;
}

.separator {
  height: 2px;
  border-radius: 1px;
  background: var(--grey-2-lines);
  width: 100%;
  display: inline-block;
}

.content {
  margin-top: 10px;
}

.dropdownButton {
  justify-content: flex-start;
}
</style>
