import { useApiClient } from '@/api'
import type { RunInputDataItemFragment, RunWorkflowDetailsFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { computed, reactive, ref } from 'vue'

export function useRunWorkflowForm() {
  const { client } = useApiClient()

  const submitted = ref(false)

  const data = reactive({
    createNewInputData: false,
    name: '',
    dataObj: {} as Record<string, unknown>,
    selectedInputDataId: '',
  })

  const { validate, errors } = useValidation({
    name: { value: computed(() => data.name), validators: [nameValidator] },
    payload: { value: computed(() => data.dataObj), validators: [payloadValidator] },
  })

  const workflowId = ref('')
  const runWorkflowDetails = ref<RunWorkflowDetailsFragment>()
  const workflowInputArgs = computed(() => runWorkflowDetails.value?.inputArguments ?? [])
  const organizationId = computed(() => runWorkflowDetails.value?.organization.id ?? '')

  const availableInputDatas = ref<RunInputDataItemFragment[]>()

  const shownArgNames = computed(() => Object.keys(data.dataObj).sort((a, b) => a.localeCompare(b)))
  const inputArgNames = computed(() => workflowInputArgs.value.map((arg) => arg.name))

  const requiredArgNames = computed(() => {
    if (!workflowInputArgs.value) return []
    const requiredArgs = workflowInputArgs.value.filter((arg) => arg.required && arg.argumentType !== 'Boolean')
    return requiredArgs.map((arg) => arg.name)
  })

  const missingRequiredNames = computed(() => {
    return requiredArgNames.value.filter((name) => data.dataObj[name] == null || data.dataObj[name] === '')
  })

  const payload = computed(() => {
    // Only includes the parameters that are actually used
    return inputArgNames.value.reduce<Record<string, unknown>>((acc, key) => {
      acc[key] = data.dataObj[key]
      return acc
    }, {})
  })

  async function initForm(args: { workflowId: string; useInputData?: boolean; isPublicWorkflow?: boolean }) {
    workflowId.value = args.workflowId
    data.name = ''
    data.createNewInputData = false
    data.selectedInputDataId = ''
    data.dataObj = {}

    const fetchArgsPromise = args.isPublicWorkflow ? fetchPublicWorkflowArgs() : fetchWorkflowArgs()
    const inputsPromise = args.useInputData ? fetchInputDatas() : Promise.resolve()
    await Promise.all([fetchArgsPromise, inputsPromise])

    // Initialise the data object with the default values
    for (const key of inputArgNames.value) {
      data.dataObj[key] = getDefaultValue(key)
    }
  }

  async function fetchInputDatas() {
    const input = {
      workflow: { id: workflowId.value },
      organization: { id: organizationId.value },
    }
    const response = await client.runInputData({ input })
    availableInputDatas.value = response.runInputData
  }

  async function fetchPublicWorkflowArgs() {
    const response = await client.publicWorkflowInputArguments({ id: workflowId.value })
    runWorkflowDetails.value = response.publicWorkflow
  }

  async function fetchWorkflowArgs() {
    const response = await client.workflowInputArguments({ id: workflowId.value })
    runWorkflowDetails.value = response.workflow[0]
  }

  function getDefaultValue(argName: string) {
    const arg = workflowInputArgs.value?.find((arg) => arg.name === argName)
    if (arg?.argumentType === 'Boolean') return false
    return ''
  }

  function loadInputData(inputData: RunInputDataItemFragment) {
    const prevPayload = data.dataObj
    data.name = inputData.name
    // Load the stored input data and merge it with the current payload
    data.dataObj = {}
    for (const key of inputArgNames.value) {
      data.dataObj[key] = prevPayload[key]
    }
    Object.entries(inputData.data ?? {}).forEach(([key, value]) => {
      data.dataObj[key] = value
    })
  }

  async function submitRun() {
    submitted.value = true
    const valid = await validate()
    if (!valid) return null
    if (data.createNewInputData) await submitInputData()
    const result = await client.runByWorkflowId({ workflowId: workflowId.value, data: payload.value })
    return result.runByWorkflowId.id
  }

  async function submitInputData() {
    const organization = { id: organizationId.value }
    const workflow = { id: workflowId.value }
    const input = { name: data.name, data: payload.value, organization, workflow }
    await client.createRunInputData({ input })
  }

  function getArgValue(argName: string) {
    const value = data.dataObj[argName]
    if (!value) return ''
    if (typeof value === 'string') return value
    return value
  }

  function setArgValue(argName: string, value: unknown) {
    data.dataObj[argName] = value
  }

  function getArgWarn(argName: string) {
    const unused = !inputArgNames.value.includes(argName)
    if (unused) return 'This argument is no longer used by the workflow and will be ignored'
  }

  function getArgError(argName: string) {
    if (!submitted.value) return undefined
    if (missingRequiredNames.value.includes(argName)) return 'Missing required value'
    return undefined
  }

  function getArgDetails(argName: string) {
    const arg = workflowInputArgs.value?.find((arg) => arg.name === argName)
    return arg
  }

  function payloadValidator() {
    if (missingRequiredNames.value.length) return 'Missing one or more required values'
    return undefined
  }

  function nameValidator(value: string) {
    if (!data.createNewInputData) return undefined
    if (data.createNewInputData && !value) return 'Name is required'
    if (availableInputDatas.value?.find((input) => input.name === value)) return 'Name already exists'
    return undefined
  }

  return {
    data,
    errors,
    availableInputDatas,
    shownArgNames,
    requiredArgNames,
    runWorkflowDetails,
    initForm,
    loadInputData,
    submitRun,
    getArgValue,
    getArgDetails,
    setArgValue,
    getArgError,
    getArgWarn,
  }
}
