import { names } from './names'

export const links = {
  // Auth:
  login: () => ({ name: names.login }),
  signup: () => ({ name: names.signup }),
  logout: () => ({ name: names.logout }),
  emailVerification: () => ({ name: names.emailVerification }),
  activationRequired: () => ({ name: names.activationRequired }),
  inviteAccept: (inviteId: string) => ({ name: names.inviteAccept, params: { inviteId } }),
  forgotPassword: () => ({ name: names.forgotPassword }),
  setPassword: () => ({ name: names.setPassword }),

  // User:
  userSetting: () => ({ name: names.userSetting }),
  organization: () => ({ name: names.organization }),

  // Workflows:
  workflows: () => ({ name: names.workflows }),
  workflowData: (workflowId: string) => ({ name: names.workflowData, params: { workflowId } }),
  publicRunWorkflow: (workflowId: string) => ({ name: names.publicRunWorkflow, params: { workflowId } }),
  runWorkflow: (workflowId: string) => ({ name: names.runWorkflow, params: { workflowId } }),

  // Workflow edit:
  workflowEdit: (workflowId: string) => ({ name: names.workflowEdit, params: { workflowId } }),
  reviewData: (workflowId: string, workflowBlockId: string) => ({
    name: names.reviewData,
    params: { workflowId, workflowBlockId },
  }),

  // Runs:
  runs: () => ({ name: names.runs }),
}
